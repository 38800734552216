import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_q = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - Q'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>Q</h2>
     <LatQuotesIntro />
    
     <p><b>Qua</b> - In so far as</p>
<p><b>Quae nocent, saepe docent</b> - What hurts, often instructs. One learns by bitter/adverse experience</p>
<p><b>Quae vide (qqv)</b> - See these things</p>
<p><b>Quaere verum</b> - Seek the truth</p>
<p><b>Quaere</b> - (You might) ask. Used to introduce questions, usually rhetorical or tangential questions</p>
<p><b>Qualis artifex pereo</b> - Such an artist dies in me (Emperor Nero's famous last words)</p>
<p><b>Qualis pater talis filius</b> - As is the father, so is the son; like father, like son</p>
<p><b>Quam bene vivas refert, non quam diu</b> - The important thing isn't how long you live, but how well you live. (Seneca)</p>
<p><b>Quam se ipse amans sine rivali!</b> - Himself loving himself so much</p>
<p><b>Quam terribilis est haec hora</b> - How fearful is this hour</p>
<p><b>Quandam</b> - Formally</p>
<p><b>Quando omni flunkus moritatus</b> - When all else fails play dead</p>
<p><b>Quantum meruit</b> - As much as he/she deserved</p>
<p><b>Quantum sufficit (qs)</b> - As much as suffices</p>
<p><b>Quaque mane (qm)</b> - Every morning</p>
<p><b>Quaque nocte (qn)</b> - Every night</p>
<p><b>Quasi</b> - As if</p>
<p><b>Quater in die (Q.I.D)</b> - Take four times a day</p>
<p><b>Quem di diligunt, adolescens moritur</b> - Whom the gods love dies young. (only the good die young)</p>
<p><b>Quemadmoeum gladis nemeinum occidit, occidentis telum est</b> - A sword is never a killer, it's a tool in the killer's hands. (Seneca)</p>
<p><b>Qui bene amat bene castigat</b> - Who loves well castigates well. Spare the rod and spoil the child</p>
<p><b>Qui bene cantat, bis orat</b> - He who sings well, prays twice</p>
<p><b>Qui bono?</b> - Who benfits?</p>
<p><b>Qui dedit benificium taceat; narret qui accepit</b> - Let him who has done a good deed be silent; let him who has received it tell it. (Seneca)</p>
<p><b>Qui desiderat pacem praeparat bellum</b> - Who desires peace [should] prepare [for] war</p>
<p><b>Qui desiderat pacem, praeparet bellum</b> - Let him who wishes for peace prepare for war. (Vegetius)</p>
<p><b>Qui docet discit</b> - He who teaches learns</p>
<p><b>Qui dormit, non peccat</b> - One who sleeps doesn't sin</p>
<p><b>Qui habet aures audiendi audiat</b> - He who has ears, let him understand how to listen</p>
<p><b>Qui ignorabat, ignorabitur</b> - One who is ignorant will remain unnoticed</p>
<p><b>Qui me amat, amet et canem meum</b> - Who love mes, let him love my dog</p>
<p><b>Qui multum habet, plus cupit</b> - He who has much desires more. (Seneca)</p>
<p><b>Qui nescit dissimlare nescit regnare</b> - He who doesn't know how to lie doesn't know how to rule</p>
<p><b>Qui nimium probat, nihil probat</b> - One who proves too much, proves nothing</p>
<p><b>Qui non est hodie cras minus aptus erit</b> - He who is not prepared today will be less so tomorrow. (Ovid)</p>
<p><b>Qui omnes insidias timet in nullas incidit</b> - He who fears every ambush falls into none. (Pubilius Syrus)</p>
<p><b>Qui potest capere capiat</b> - Let him accept it who can</p>
<p><b>Qui pro innocente dicit, satis est eloquens</b> - He who speaks for the innocent is eloquent enough. (Publius Syrus)</p>
<p><b>Qui scribit bis legit</b> - He who writes reads twice</p>
<p><b>Qui tacet consentire videtur</b> - He that is silent is thought to consent</p>
<p><b>Qui tacet consentit</b> - Silence gives consent</p>
<p><b>Qui vivat atque floreat ad plurimos annos</b> - May he live and flourish for many years</p>
<p><b>Qui vult dare parva non debet magna rogare</b> - He who wishes to give little shouldn't ask for much</p>
<p><b>Quia natura mutari non potest idcirco verae amicitiae sempiternae sunt</b> - Since nature cannot change, true friendships are eternal. (Horace)</p>
<p><b>Quid novi?</b> - What's new? 'What's up?'</p>
<p><b>Quid nunc</b> - What now?! (a nosy busybody)</p>
<p><b>Quid pro quo</b> - Something for something. i.e. A favor for a favor</p>
<p><b>Quid quid latine dictum sit, altum videtur</b> - Anything said in Latin sounds profound</p>
<p><b>Quid rides? Mutato nomine de te fabula narratur</b> - What are you laughing at? Just change the name and the joke's on you. (Horace)</p>
<p><b>Quidnunc? Or Quid nunc?</b> - What now? As a noun, a quidnunc is a busybody or a gossip</p>
<p><b>Quidquid agis, prudenter agas et respice finem!</b> - Whatever you do, do cautiously, and look to the end</p>
<p><b>Quidquid id est, timeo Danaos et dona ferentes</b> - Whatever it is, I fear the Greeks, even bearing gifts. (Vergil)</p>
<p><b>Quidquid latine dictum sit, altum videtur</b> - Anything said in Latin sounds profound</p>
<p><b>Quidvis Recte Factum Quamvis Humile Praeclarum</b> - Whatever is rightly done, however humble, is noble</p>
<p><b>Quieta non movere</b> - Don't move settled things, or Don't rock the boat</p>
<p><b>Quinon proficit deficit</b> - He who does not advance, go backwards</p>
<p><b>Quique amavit, cras amet</b> - May he love tomorrow who has never loved before;</p>
<p><b>Quis custodiet ipsos custodes</b> - Who shall keep watch over the guardians? (Juvenalis) Don't assign a fox to guard the henhouse</p>
<p><b>Quis separabit?</b> - Who shall separate us?</p>
<p><b>Quis, quid, ubi, quibus auxiliis, cur, quomodo, quando?</b> - Who, what, where, with what, why, how, when?</p>
<p><b>Quo ad hoc</b> - As much as this (to this extent)</p>
<p><b>Quo animo?</b> - With what spirit? (or intent?)</p>
<p><b>Quo fas et gloria docunt</b> - Where right and glory lead</p>
<p><b>Quo iure?</b> - By what law?</p>
<p><b>Quo usque tandem abutere, catilina, patientia nostra?</b> - How long will you abuse our patience, Catiline? (Cicero)</p>
<p><b>Quo vadis?</b> - Where are you going? / Whither goest thou?</p>
<p><b>Quod bonum, felix faustumque sit!</b> - May it be good, fortunate and prosperous! (Cicero)</p>
<p><b>Quod cibus est aliis, aliis est venenum</b> - What is food to some is poison to others. One man's meat is another poison</p>
<p><b>Quod cito acquiritur cito perit</b> - [that] which is quickly acquired [is] quickly lost. Eeasy come, easy go</p>
<p><b>Quod differtur, non aufertur</b> - That which is postponed is not dropped. Inevitable is yet to happen. (Sir Thomas More)</p>
<p><b>Quod erat demonstrandum (QED)</b> - Which was to be demonstrated</p>
<p><b>Quod erat faciendum (QEF)</b> - Which was to be done</p>
<p><b>Quod erat in veniendum</b> - Which was to be found</p>
<p><b>Quod est (qe)</b> - Which is</p>
<p><b>Quod incepimus conficiemus</b> - What we have begun we shall finish</p>
<p><b>Quod licet Iovi non licet bovi</b> - What Jupiter (supreme God) is allowed to do, cattle (people) are not</p>
<p><b>Quod minimum specimen in te ingenii?</b> - What microscopic evidence of wit can be found in you?</p>
<p><b>Quod natura non sunt turpia</b> - What is natural cannot be bad</p>
<p><b>Quod vide (q.v)</b> - Which see (a scholarly gloss)</p>
<p><b>Quod vide (qv)</b> - See this thing</p>
<p><b>Quomodo cogis comas tuas sic videri?</b> - How do you get your hair to do that?</p>
<p><b>Quomodo vales</b> - How are you?</p>
<p><b>Quorum</b> - Of whom</p>
<p><b>Quos amor verus tenuit, tenebit</b> - True love will hold on to those whom it has held. (Seneca)</p>
<p><b>Quot homines, tot sententiae</b> - As many men, so as many opinions</p>

   </Layout>
  )
}

export default LatQuotes_q
